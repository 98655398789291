<template>
    <div v-if="displayLanguageSelectComputed" class="select-container">
        <vue-select
            :model-value="selectedComputed"
            :options="availableLanguagesComputed"
            :clearable="false"
            :searchable="false"
            append-to-body
            :calculate-position="calculatePosition"
            :input-id="inputId"
            @update:model-value="changeLanguage"     
        >
            <template #open-indicator="{ attributes }">
                <span
                    v-bind="attributes"
                    class="select-open-indicator d-flex align-items-center"
                ><i
                    class="fas fa-caret-down"
                    :class="disabled ? '': 'color-primary-color'"
                ></i></span>
            </template>
            <template #option="option">
                <span :title="option.tooltip">{{ option.languageString }}</span>
            </template>
            <template #selected-option="option">
                <span :title="option.tooltip">{{ option.languageString }}</span>
            </template>
        </vue-select>
    </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import VueSelect from 'vue-select';
import { nextTick } from 'vue';
export default {
    name: "SelectLanguage",
    components: {
        VueSelect
    },
    props: {
        disabled: {
            type: Boolean,
            default: false,
        },
        inputId: {
            type: String,
            default: "",
        }
    },
    computed: {
        ...mapGetters(['isSurveyAnswerBaseModelLoaded', 'getAvailableLanguages', 'getUserLanguage', 'getUserLanguageToLoad', 'getCurrentSurveyLanguage' ]),
        availableLanguagesComputed() {
            if(this.isSurveyAnswerBaseModelLoaded) {
                return this.getAvailableLanguages;
            }
            return [];
        },
        selectedComputed() {
            let userLanguage = this.availableLanguagesComputed.find(l => l.languageIso === this.userLanguageComputed);
            if(userLanguage){
                return userLanguage;
            }
            return this.getCurrentSurveyLanguage;
        },
        displayLanguageSelectComputed(){
            return this.availableLanguagesComputed.length > 1;
        },
        userLanguageComputed(){
            return this.getUserLanguageToLoad ?? this.getUserLanguage; 
        },

    },
    methods: {
        ...mapActions(['changeLanguage']),

        async calculatePosition(dropdownList, component) {
            await nextTick();
            
            let scrollX = window.scrollX || window.pageXOffset
            let scrollY = window.scrollY || window.pageYOffset
            const { width, bottom, left } = component.$el.getBoundingClientRect();

            dropdownList.style.top = `${bottom + scrollY}px`;
            dropdownList.style.left = `${left + scrollX}px`;
            dropdownList.style.width = `${width}px`;
        },
    },
}
</script>
<style lang="scss" scoped>

.select-container{
    width: 15rem;
    margin: 0;
    display: flex;
    align-items: center;

    :deep(.v-select) {
        flex-grow: 1;
        min-width: 0;
    }

    :deep(.vs__dropdown-menu){
        min-width: fit-content;
    }

    :deep(.vs__search::placeholder) {
        color: rgb(117, 117, 117);
    }

    :deep(.vs--disabled){
        .vs__dropdown-toggle, .vs__search {
            background-color: initial;
        }
        .vs__search::placeholder {
            opacity: 50%;
        }
        .select-open-indicator{
            background-color: initial;
        }
        .vs__selected {
            color: #a6a6a6;

            span {
                white-space: nowrap;
                text-overflow: ellipsis;
            }
        }
    }

}

</style>