<template>
    <div class="question-text-group" :class="isInstructionWithoutChoices ? 'instruction' : ''" :style="{paddingLeft: paddingLeft + 'em', paddingRight: paddingLeft + 'em'}">
        <div v-if="!isInstructionWithoutChoices" class="optional-required-container">
            <span v-if="!question.required" class="text-muted">
                {{$t('question.optional')}}
            </span>
            <span v-else-if="question.required && highlightedHasBeenTriggered" class="required-style" :class="getHighlightStyle" >
                {{$t('question.requiredQuestion')}}
            </span>
        </div>
        <span v-if="isInstructionMardown" class="v-html instruction-text" v-html="instructionComputed"></span>
        <h2 v-else class="question-text html" v-html="instructionComputed"></h2>  
        <div v-if="hasInstructionExplanationComputed" class="mt-1 v-html text-muted" v-html="instructionExplanationComputed"></div>
        <pop-toggle v-if="showPopoverComputed" :pop-toggle="popoverComputed"/>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { Functions } from '../helpers/functions';
import PopToggle from "./PopToggle.vue";

export default {
    name: "QuestionTextGroup",
    components: { 
        PopToggle, 
    },
    props: {
        question: {
            type: Object,
            required: true,
            default: () => {},
        },
        isInstructionWithoutChoices: {
            type: Boolean,
            required: false,
            default: false,
        },
        paddingLeft:{
            type: Number,
            required: false,
            default: 2,
        }
    },  
    computed: {
        ...mapGetters(['isQuestionHighlighted', 'highlightedHasBeenTriggered']),
        instructionComputed() {
            return Functions.renderMarkdown(this.question.instruction, true);
        },
        instructionExplanationComputed() {
            return Functions.renderMarkdown(this.question.instructionExplanation, false);
        },
        hasInstructionExplanationComputed(){
            return this.question.instructionExplanation !== null && this.question.instructionExplanation !== undefined;
        },
        isInstructionMardown(){
            return Functions.stringContainsHtmlTags(this.instructionComputed);
        },
        popoverComputed() {
            return {
                id: `popPopover${this.question.id}`,
                // title: this.question.name,
                title: "Additional question information",
                explantoryText: this.question.additionalInstruction,
            }
        },
        showPopoverComputed() {
            return this.question.additionalInstruction !== null && this.question.additionalInstruction !== undefined;
        },        
        getHighlightStyle(){
            return this.isQuestionHighlighted(this.question.id) ? 'unanswered-style-text' : '';
        },
    },
}

</script>

<style lang="scss" scoped>
    .optional-required-container{
        height: 2.5em;
        display: flex;
        flex-direction: row;
        align-items: center;
        margin-right: -1em;
    }
    .question-text-group.instruction {
        padding: 1.5em 2em 0 2em;
    }
    .question-text {
        // padding-right: 1.33em;
        max-width: 35ch;
        font-size: 1.2em;
        margin-bottom: 0;
        text-wrap: balance;
        text-wrap: pretty;
    }
    .instruction-text{
        :deep(h5) {
            font-size: 1.2em;
        }
        :last-child {
            margin-bottom: 0;
        }
    }
    span.text-muted{
        font-weight: 300;
        font-style: italic;
    }
    span.required-style{
        font-weight: 300;
        font-style: italic;

    }
</style>